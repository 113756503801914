<script lang="ts" setup generic="T">
import type { KeenSliderOptions } from "keen-slider";

const props = withDefaults(
	defineProps<{
		title: string;
		showAll?: boolean;
		showNavButtons?: boolean;
		list: T[];
		spacing?: number;
		icon?: string;
		isAdminIcon?: boolean;
	}>(),
	{
		showAll: false,
		showNavButtons: true,
		spacing: 16
	}
);

const emit = defineEmits<{ (event: "viewAllAction"): void }>();

const { t } = useT();

const sliderActiveSlide = ref(0);
const isLast = ref(false);
const sliderRef = ref();
const isSliderLoaded = ref(false);
const interval = ref();

const { list } = toRefs(props);

watch(
	() => list.value,
	() => {
		nextTick(() => {
			isLast.value = false;
			sliderRef.value?.slider?.update();
			isSliderLoaded.value = true;
		});
	}
);

onMounted(() => {
	isSliderLoaded.value = true;
});

const sliderOptions: KeenSliderOptions = {
	loop: false,
	mode: "free-snap",
	slides: { perView: "auto", spacing: props.spacing },
	created(slider) {
		sliderActiveSlide.value = slider.track?.details?.abs;
		isLast.value = slider.track.details.slides[props?.list.length - 1].portion >= 0.98;
		slider.update();
	},
	slideChanged(slider) {
		sliderActiveSlide.value = slider.track?.details?.abs;
	},
	dragged(slider) {
		isLast.value = slider.track.details.slides[props?.list.length - 1].portion >= 0.98;
	},
	animationEnded(slider) {
		isLast.value = slider.track.details.slides[props?.list.length - 1].portion >= 0.98;
	}
};

const slidesLength = computed(() => sliderRef.value?.slider?.slides.length ?? 0);

const prevSlide = () => {
	if (slidesLength.value > 0) {
		sliderRef.value?.slider?.prev();
	}
};

const nextSlide = () => {
	if (slidesLength.value > 0 || !isLast.value) {
		sliderRef.value?.slider?.next();
	}
};
const getDataTid = (index?: number) => {
	if (index === 0) {
		return `slider-first-item`;
	}
	return null;
};

onBeforeUnmount(() => {
	clearInterval(interval.value);
});
</script>
<template>
	<div class="box-slider">
		<ASlider v-if="list?.length" ref="sliderRef" :options="sliderOptions">
			<MHomeSectionHeader
				class="header-slider"
				:title="t(title)"
				:icon="icon || ''"
				:isAdminIcon="isAdminIcon"
				:showAll="showAll"
				showNavButtons
				:sliderActiveSlide="!!sliderActiveSlide"
				:isLast="isLast"
				@view-all-action="emit('viewAllAction')"
				@prev-slide="prevSlide"
				@next-slide="nextSlide"
			/>

			<div :class="['cards-feed__container', { loading: !isSliderLoaded }]">
				<slot name="custom" />
				<div v-for="(item, i) in list" :key="i" :data-tid="getDataTid(i)">
					<slot :item="item" />
				</div>
			</div>
		</ASlider>
	</div>
</template>

<style lang="scss" scoped>
.cards-feed__container {
	display: flex;
	position: relative;
	padding-top: 52px;
	overflow: hidden;
	flex: 1;

	&.loading {
		gap: 12px;
		@include media-breakpoint-up(lg) {
			gap: 16px;
		}
	}
	@include media-breakpoint-down(md) {
		overflow: visible;
	}
}

.header-slider {
	position: absolute;
	top: 0;
	z-index: 1;
}

.box-slider {
	@include media-breakpoint-down(md) {
		width: calc(100% + 32px);
		transform: translateX(-16px);
		overflow: hidden;
		padding: 0 16px;

		&:deep(.keen-slider) {
			overflow: visible;
		}
	}
}
</style>
